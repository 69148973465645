.lia-modal {
  .lia-header {
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 10px;
    .lia-title {
      margin-right: 0;
      font-weight: var(--lia-bs-font-weight-bold);
      text-align: center;
      font-size: var(--lia-bs-h4-font-size);
      letter-spacing: var(--lia-h4-letter-spacing);
      line-height: var(--lia-bs-headings-line-height);
    }
  }
  .lia-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    row-gap: 30px;
  }
  .lia-footer {
    display: flex;
    justify-content: center;
    flex-direction: row-reverse;
    column-gap: 10px;
  }
}
